.body-class-page-template-template-new-home .main-title-wrapper {
    padding-left: 0;
    margin-left: -15px;
}

.body-class-page-template-template-new-home .cta-section-inner {
    margin-left: -30px;
    margin-right: 0;
}

.body-class-page-template-template-new-home .cta-bar-section {
    display: table;
    height: 170px;
}

.body-class-page-template-template-new-home .cta-bar-section .inner {
    display: table-cell;
    width: 340px;
    vertical-align: middle;
}

.body-class-page-template-template-new-home .cta-bar-section .inner h3 {
    color: white;
    font-size: 22px;
    font-family: "din", Helvetica, Arial, sans-serif;
    font-weight: 200;
    line-height: 1em;
}

.body-class-page-template-template-new-home .cta-bar-section .inner h4 {
    color: white;
    font-size: 18px;
    font-family: "din", Helvetica, Arial, sans-serif;
    font-weight: 200;
    line-height: 1em;
}

.body-class-page-template-template-new-home .cta-bar {
    background: #72CFE7;
}

.body-class-page-template-template-new-home .cta-bar .cta-section-inner {
    position: relative;
    top: -1px;
    height: 170px;
    background: url("/wp-content/themes/blu/images/homepage-cta-bar.png") no-repeat;
    padding-left: 120px;
}

.body-class-page-template-template-new-home .cta-bar-section.cta-bar-centre,
.body-class-page-template-template-new-home .cta-bar-section.cta-bar-right {
    margin-left: -10px;
}

.body-class-page-template-template-new-home .header-bottom {
    text-align: center;
}

.body-class-page-template-template-new-home .header-bottom .title-holder .slogan,
.body-class-page-template-template-new-home .header-bottom .title-holder .title {
    text-align: left;
}

.body-class-page-template-template-new-home .cta-btn {
    text-decoration: none;
    color: white;
    border: 2px solid white;
    display: inline-block;
    margin-bottom: 0;
    font-weight: bold;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-image: none;
    border-radius: 4px;
    white-space: nowrap;
    padding: 8px 8px;
    font-size: 14px;
}

.body-class-page-template-template-new-home .cta-btn:hover {
    opacity: 0.75;
    text-decoration: none;
}

.body-class-page-template-template-new-home .header-bottom .bg-stretch img.clouds {
    width: 1013px !important;
    height: auto !important;
    margin-top: -20px !important;
    z-index: 0;
}

.body-class-page-template-template-new-home .header-bottom .bg-stretch img.home-header {
    width: 1013px !important;
    height: auto !important;
    margin-top: -410px !important;
    z-index: 1;
}

.body-class-page-template-template-new-home .header-bottom .title-holder:after {
    display: none;
    background: none;
}

.body-class-page-template-template-new-home .home-map-inner {
    float: right;
    z-index: 0;
}

.body-class-page-template-template-new-home .home-map-inner img {
    left: 70px;
    position: relative;
}

.body-class-page-template-template-new-home .home-map-text {
    position: absolute;
    top: 167px;
    left: 10px;
    z-index: 1;
}

.body-class-page-template-template-new-home .home-map-text h3 {
    color: #23a2dc;
    font-size: 30px;
    font-weight: 300;
    text-transform: none;
}

.body-class-page-template-template-new-home .home-map-text h3 strong {
    text-transform: uppercase;
    font-weight: 400;
}

.body-class-page-template-template-new-home .home-map-text .cta-btn {
    color: #23a2dc;
    border: 2px solid #23a2dc;
    text-transform: uppercase;
}

.body-class-page-template-template-new-home .home-map-text .cta-map-btn {
    border: none;
    color: white;
    background: #F6700B;
}

/*.body-class-page-template-template-new-home */
.cta-slogan-btn {
    text-decoration: none;
    color: white;
    border: none;
    background: #F6700B;
    display: inline-block;
    margin-bottom: 0;
    font-weight: bold;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-image: none;
    border-radius: 4px;
    white-space: nowrap;
    padding: 8px 8px;
	font-size: 14px;
	border-width: 0 !important;
}

.cta-slogan-btn.green {
    background-color: #a9cc48;
}

.cta-slogan-btn.lrg {
    font-size: 18px;
}

@media only screen and (min-width: 2027px) {
    .body-class-page-template-template-new-home .header-bottom .bg-stretch img.home-header {
        width: 1203px !important;
        margin-top: -160px !important;
    }

    .body-class-page-template-template-new-home .header-bottom .bg-stretch img.clouds {
        display: none !important;
    }
}

@media only screen and (max-width: 1200px) {
    .body-class-page-template-template-new-home .cta-bar .cta-section-inner {
        background: none;
        padding: 0 40px;
        height: auto;
    }

    .body-class-page-template-template-new-home .cta-bar .cta-bar-section {
        background: url("/wp-content/themes/blu/images/single-cta-bar.png") no-repeat;
    }

    .body-class-page-template-template-new-home .header-bottom .bg-stretch img.clouds {
        display: none;
    }

    .body-class-page-template-template-new-home .cta-section-inner {
        margin: 0;
    }

}

@media only screen and (max-width: 768px) {
    .body-class-page-template-template-new-home .main-title-wrapper {
        margin-left: 0;
    }

    .body-class-page-template-template-new-home .cta-bar .cta-section-inner {
        background: none;
        padding: 0 40px;
        height: auto;
    }

    .body-class-page-template-template-new-home .cta-bar .cta-bar-section {
        /*background: url("/wp-content/themes/blu/images/single-cta-bar.png") no-repeat;*/
        background: none;
        height: 90px;
        border-bottom: 2px solid #3FA4F0;
        margin-left: 0;
    }

    .body-class-page-template-template-new-home .cta-bar .cta-bar-section:last-child {
        border: none;
    }

    .body-class-page-template-template-new-home .cta-bar-section .inner {
        width: 100%;
        height: 90px;
    }

    .body-class-page-template-template-new-home .home-map-text {
        top: 30px;
    }

    .body-class-page-template-template-new-home .home-map-inner img {
        left: 120px;
        opacity: 0.45;
    }

    .body-class-page-template-template-new-home .cta-bar-section .inner h3 {
        width: 70%;
        float: left;
        margin-bottom: 0;
    }

    .body-class-page-template-template-new-home .cta-bar-section .inner h4 {
        width: 70%;
        float: left;
        margin-bottom: 0;
    }

    .body-class-page-template-template-new-home .cta-bar-section .inner a {
        width: auto;
        float: right;
    }
}
