.nav.navbar-nav > li.my-profile .profile-drop-down {
    display: none;
    border-top: 1px solid #aeaeae;
    background: #EBF1F9;
    position: absolute;
    height: 120px;
    top: 92px;
    left: 0;
    width: 100%;
    margin: 0;
    padding: 10px 0 0 0;
    z-index: 100;
}

.nav.navbar-nav > li.my-profile .profile-drop-down.one-items {
    height: 45px;
}

.nav.navbar-nav > li.my-profile .profile-drop-down.two-items {
    height: 85px;
}

.nav.navbar-nav > li.my-profile .profile-drop-down.three-items {
    height: 125px;
}

.nav.navbar-nav > li.my-profile .profile-drop-down.four-items {
    height: 185px;
}

.nav.navbar-nav > li.my-profile .profile-drop-down.five-items {
    height: 205px;
}

.nav.navbar-nav > li.my-profile .profile-drop-down.six-items {
    height: 260px;
}

.nav.navbar-nav > li.my-profile .profile-drop-down span {
    overflow: visible;
    width: 100%;
    margin: 0;
    padding: 0;
}

.nav.navbar-nav > li.my-profile .profile-drop-down-inner span {
    padding: 5px 20px;
    height: 40px;
}

.nav.navbar-nav > li.my-profile .profile-drop-down-inner span:first-child {
    padding-top: 8px;
}

.nav.navbar-nav > li.my-profile .profile-drop-down-inner span a {
    font-weight: 700;
    text-decoration: none;
    text-transform: uppercase;
}

.nav.navbar-nav > li.my-profile .profile-drop-down-inner span a:hover {
    text-decoration: none;
    opacity: 0.75;
}

.nav.navbar-nav > li.my-profile .profile-drop-down-inner span.glyphicon {
    padding: 0;
    margin: 0 15px 0 0;
    height: auto;
    width: auto;
    display: inline;
}

.profile-drop-down-inner a {
    font-size: 14px;
    color: #4A90E2 !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}