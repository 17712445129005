.navbar-nav {
    /*height: 69px !important;*/
}

.nav.navbar-nav  > li.my-profile {
    border-bottom: none !important;
    font-size: 14px !important;
}

.nav.navbar-nav  > li.my-profile > a {
    display: table-cell;
    vertical-align: middle;
    padding: 0 !important;
    border: none;
    font-family: "DIN", Helvetica, sans-serif;
    font-weight: normal;
}

.nav.navbar-nav  > li.my-profile {
    top: -22px;
    display: table;
    height: 92px;
    background: #EBF1F9;
    border-bottom: 4px solid transparent;
    color: #656D71;
    font-size: 14px;
    margin-left: 60px;
}

.nav.navbar-nav  > li.my-profile img {
    width: 47px;
    height: 47px;
    float: left;
    border-radius: 25px;
    margin: 10px 20px 10px 35px;
}

.nav.navbar-nav  > li.my-profile span {
    margin: 10px 0 10px 90px;
    height: 23px;
    text-transform: none;
    display: block;
    width: 70px;
    padding-right: 36px;
    overflow: hidden;
}

.nav.navbar-nav  > li.my-profile span.name {
    margin-bottom: 0;
    margin-top: 13px;
}

.nav.navbar-nav  > li.my-profile span.role {
    margin-top: 0;
}

.nav.navbar-nav  > li.my-profile span.single {
    margin-top: 25px;
}

.nav.navbar-nav  > li.my-profile:hover {
    background: #d1e1f6;
    opacity: 1;
    color: #23313a !important;
}

.nav.navbar-nav  > li.my-profile > a:active,
.nav.navbar-nav  > li.my-profile > a:focus,
.nav.navbar-nav  > li.my-profile > a:hover {
    opacity: 1;
    color: #23313a !important;
}

@media only screen and (max-width: 767px) {
    .nav.navbar-nav  > li.my-profile {
        width: 95%;
        margin-top: 40px;
    }
    .nav.navbar-nav  > li.my-profile span.name {
        float: right;
        display: inline-block;
        margin-left: 20px;
        margin-right: 60px;
    }
    .nav.navbar-nav  > li.my-profile .profile-drop-down {
        display: block !important;
    }
    .nav.navbar-nav  > li.my-profile .profile-drop-down span {
        display: block !important;
    }
}

@media only screen and (max-width: 1200px) {

    .nav.navbar-nav  > li.my-profile {
        margin-left: 10px;
    }
}

@media only screen and (max-width: 991px) {

    .nav.navbar-nav  > li.my-profile img {
        margin: 10px;
    }

    .nav.navbar-nav  > li.my-profile span {
        display: none;
    }
}