body[data-lang="pt-br"] .models-inner .col-sm-6 a.video {
    width: 226px;
}

body[data-lang="pt-br"] .models-inner .nav li {
    top: 8px;
}

body[data-lang="pt-br"] .models-inner .nav li a {
    padding: 0 0 15px 0;
    min-height: 49px;
}

.map-boy {
    position: relative;
}

.translations ul {
    position: absolute;
    right: 0;
    top: 0;
    margin-top: 20px;
    margin-right: 20px;
}

.translations ul li {
    float: left;
    list-style: none;
}

.translations ul li {
    list-style: none;
    padding: 10px;
    height: 20px;
    line-height: 3px;
}

.translations ul li a {
    color: #fff;
    text-decoration: none;
    font-weight: bold;
}

body[data-lang="en"] .translations .trans-en {
    display: none;
}

body[data-lang="ms"] .translations .trans-ms {
    display: none;
}

body[data-lang="pt-br"] .translations .trans-pt-br {
    display: none;
}

body[data-lang="en"] .translations .trans-pt-br {
    border-right: 1px solid #fff;
}

body[data-lang="pt-br"] .translations .trans-en {
    border-right: 1px solid #fff;
}

body[data-lang="ms"] .translations .trans-en {
    border-right: 1px solid #fff;
}

@media only screen and (max-width: 992px) {
    .translations {
        width: 100%;
    }
}