#homepage-2019 {

	.hero {
		background-image: url("assets/img/hero-bg.jpg");
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
		max-height: 465px;
		height: 100vw;
		.container {
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		.content-centered {
			max-width: 510px;
			text-align: center;
			margin-top: 8%;
			h1 {
				color: #ffffff;
				font-size: 50px;
				font-weight: 600;
				line-height: 50px;
				text-transform: uppercase;
				margin: 0 0 24px;
				&:after {
					display: none;
				}
			}
			p {
				color: #ffffff;
				font-size: 24px;
				font-weight: 500;
				line-height: 26px;
				margin: 0 0 32px;
			}
			hr {
				border: 0;
				border-top: 2px solid white;
				margin: 0 0 28px;
			}
		}
	}
	.cta-single-bar {
		background-color: #6ccfea;
		background-image: url("assets/img/cta-bar-single-bg.jpg");
		background-repeat: no-repeat;
		height: 171px;
		.box {
			padding: 20px 0;
			max-width: 335px;
			h4 {
				color: #ffffff;
				font-size: 22px;
				font-weight: 500;
				line-height: 23px;
			}
		}
	}
}
