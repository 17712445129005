body.with-comp,
body.body-class-with-comp {
    top: 59px;
    position: relative;
}

/*@media (min-width: 768px) {*/
    /*.container {*/
        /*width: 100%;*/
    /*}*/
/*}*/

.effects .overlay div {
    width: 100%;
    height: 200px;
    /*background: rgba(132,214,236,.80);*/ /*BLUE*/
    background: rgba(231,187,93,.80);
}

.effects .overlay div h3 {
    letter-spacing: 3px;
    line-height: 35px;
    font-family: "din", Helvetica, Arial, sans-serif;
    font-size: 30px;
    color: white;
    text-align: center;
    padding: 10px 2px 0 2px;
}

.effects .overlay div h3 span {
    display: block;
    margin-top: 20px;
}

.body-class-page-template-template-new-about-inner #pp_full_res .pp_inline {
    text-align: center;
}

.banner-wrap {
    display: block;
    z-index: 1000;
    position: fixed;
    text-align: center;
    width: 100%;
    top: 0;
    background: #F68A1D;
    height: 60px;
    color: #FFF;
}

.banner-inner {
    text-align: center;
    width: 75%;
    margin: 7px auto 0 auto;
    color: #FFF;
}

.banner-inner p {
    position: relative;
    top: 7px;
    font-weight: bold;
    font-size: 15px;
}

.banner-inner p a {
    margin-left: 10px;
    background: #0070CD;
    border: none;
    text-decoration: none;
    font-weight: normal;
}

.body-class-page-template-template-new-about header {
    position: relative;
    z-index: 10;
    padding-bottom: 30px;
}

.body-class-archive #twocolumns.new-about,
.body-class-page #twocolumns.new-about,
.body-class-single #twocolumns.new-about {
    background: none;
}

.body-class-page-template-template-new-about .cta-bar {
    top: -41px;
    position: relative;
}

.body-class-page-template-template-new-about .cta-bar .btn:hover {
    z-index: 9;
}

#twocolumns.new-about {
    background: none;
}

.new-about.with-shadows {
    background: url("/wp-content/themes/blu/images/background-shadows.png") no-repeat center !important;
}

.new-about img.full-slices {
    position: absolute;
    right: -60px;
    top: -55px;
    width: 833px;
    z-index: 0;
}

.new-about h1 {
    font-family: "din", Helvetica, Arial, sans-serif;
    font-weight: bold;
    font-size: 50px;
    color: #23a2dc;
    text-decoration: none;
    border: none;
    text-transform: uppercase;
    width: 300px;
    margin: 0;
    padding: 5px 0 30px 0;
}

.new-about h1:after {
    bottom: 3px;
}

.new-about h2 {
    font-family: "din", Helvetica, Arial, sans-serif;
    font-weight: normal;
    font-size: 24px;
    color: #23a2dc;
    text-decoration: none;
    border: none;
    text-transform: none;
    width: 380px;
    margin-top: 120px;
}

.new-about h3 {
    font-family: "din", Helvetica, Arial, sans-serif;
    font-weight: bold;
    font-size: 14px;
    color: #0070cd;
    text-decoration: none;
    border: none;
    text-transform: uppercase;
    width: 260px;
    margin-top: 20px;
    margin-bottom: 210px;
}

.new-about h2.secondary {
    width: 100%;
    font-size: 1.8571em;
    line-height: 1em;
    color: #a9cc48;
    text-transform: none;
    display: block;
    font-weight: 300;
    margin: 8px;
}

.new-about .split-image-panel img {
    border-bottom: 10px solid #a9cc48;
    margin-bottom: 35px;
}

.new-about .split-image-panel p {
    font-size: 16px;
    font-family: "din", Helvetica, Arial, sans-serif;
    font-weight: normal;
}

.new-about .split-image-panel .btn.btn-success {
    text-align: center;
    padding: 8px 15px 5px 15px;
    margin: 0;
    text-transform: uppercase;
    border: #a9cc48 solid 3px;
    border-radius: 6px;
    color: #a9cc48;
    background: white;
    text-decoration: none;
    font-size: 13px;
    line-height: 14px;
}

.new-about .split-image-panel .btn.btn-success:hover {
    opacity: 0.75;
}

#content .table .table-cell img.resp {
    width: 100%;
}

.body-class-page #twocolumns.new-about #content .wheel-centre {
    display: none;
    width: 180px;
    position: absolute;
    top: 250px;
    right: 245px;
}

.body-class-page #twocolumns.new-about #content .wheel-centre a,
.body-class-page #twocolumns.new-about #content .wheel-centre a:hover {
    text-decoration: none;
}

.body-class-page #twocolumns.new-about #content .wheel-centre[data-item="1"] p.wheel-number,
.body-class-page #twocolumns.new-about #content .wheel-centre[data-item="1"] p.wheel-text {
    color: #FF1500;
}

.body-class-page #twocolumns.new-about #content .wheel-centre[data-item="2"] p.wheel-number,
.body-class-page #twocolumns.new-about #content .wheel-centre[data-item="2"] p.wheel-text {
    color: #ff8404;
}

.body-class-page #twocolumns.new-about #content .wheel-centre[data-item="3"] p.wheel-number,
.body-class-page #twocolumns.new-about #content .wheel-centre[data-item="3"] p.wheel-text {
    color: #FFD308;
}

.body-class-page #twocolumns.new-about #content .wheel-centre[data-item="4"] p.wheel-number,
.body-class-page #twocolumns.new-about #content .wheel-centre[data-item="4"] p.wheel-text {
    color: #47D60D;
}

.body-class-page #twocolumns.new-about #content .wheel-centre[data-item="5"] p.wheel-number,
.body-class-page #twocolumns.new-about #content .wheel-centre[data-item="5"] p.wheel-text {
    color: #00CFFF;
}

.body-class-page #twocolumns.new-about #content .wheel-centre[data-item="6"] p.wheel-number,
.body-class-page #twocolumns.new-about #content .wheel-centre[data-item="6"] p.wheel-text {
    color: #0065DC;
}

.body-class-page #twocolumns.new-about #content .wheel-centre[data-item="7"] p.wheel-number,
.body-class-page #twocolumns.new-about #content .wheel-centre[data-item="7"] p.wheel-text {
    color: #03458A;
}

.body-class-page #twocolumns.new-about #content .wheel-centre[data-item="8"] p.wheel-number,
.body-class-page #twocolumns.new-about #content .wheel-centre[data-item="8"] p.wheel-text {
    color: #6A21AC;
}

.body-class-page #twocolumns.new-about #content .wheel-centre[data-item="9"] p.wheel-number,
.body-class-page #twocolumns.new-about #content .wheel-centre[data-item="9"] p.wheel-text {
    color: #C636E1;
}

.body-class-page #twocolumns.new-about #content .wheel-items div {
    cursor: pointer;
}

.body-class-page #twocolumns.new-about #content .wheel-centre p,
.body-class-page #twocolumns.new-about #content .wheel-items p {
    font-family: "din", Helvetica, Arial, sans-serif;
    font-size: 20px;
    font-weight: 300;
    color: #ffffff;
    margin: 0;
    line-height: 23px;
}

.body-class-page #twocolumns.new-about #content .wheel-centre p.wheel-sub-text {
    color: black;
    font-size: 16px;
    font-family: "din", Helvetica, Arial, sans-serif;
    font-weight: normal;
}

.body-class-page #twocolumns.new-about #content .wheel-centre p.wheel-number,
.body-class-page #twocolumns.new-about #content .wheel-items p.wheel-number {
    margin-bottom: 5px;
    font-size: 28px;
    font-weight: 300;
    text-transform: uppercase;
}

.body-class-page #twocolumns.new-about #content .wheel-centre p.wheel-text,
.body-class-page #twocolumns.new-about #content .wheel-items p.wheel-text {
    text-transform: uppercase;
}

.new-about .wheel-item-1 {
    position: absolute;
    right: 540px;
    top: 280px;
    width: 138px;
}

.new-about .wheel-item-2 {
    position: absolute;
    right: 490px;
    top: 110px;
    width: 120px;
}

.new-about .wheel-item-3 {
    position: absolute;
    right: 310px;
    top: 50px;
    width: 140px;
}

.new-about .wheel-item-4 {
    position: absolute;
    right: 160px;
    top: 70px;
    width: 120px;
}

.new-about .wheel-item-5 {
    position: absolute;
    right: 50px;
    top: 200px;
    width: 120px;
}

.new-about .wheel-item-6 {
    position: absolute;
    right: 50px;
    top: 370px;
    width: 120px;
}

.new-about .wheel-item-7 {
    position: absolute;
    right: 140px;
    top: 500px;
    width: 120px;
}

.new-about .wheel-item-8 {
    position: absolute;
    right: 330px;
    top: 510px;
    width: 120px;
}

.new-about .wheel-item-9 {
    position: absolute;
    right: 460px;
    top: 430px;
    width: 120px;
}

.body-class-page-template-template-new-about .quote-section {
    top: -36px;
    min-height: 400px;
    height: 400px;
    position: relative;
    z-index: 10;
    background: url("/wp-content/themes/blu/images/blue-angular-rectangle.png") no-repeat center;
    background-size: cover;
    margin-top: 40px;
}

.body-class-page-template-template-new-about .quote-section #content {
    padding-top: 0;
}

.body-class-page-template-template-new-about .quote-section blockquote {
    padding-top: 105px;
    padding-bottom: 115px;
    font-family: "din", Helvetica, Arial, sans-serif;
    font-style: italic;
    font-size: 24px;
    color: #d2f0fc;
    border: none;
    line-height: 1.5em;
    width: 75%;
    margin: 0 auto;
}

.body-class-page-template-template-new-about .quote-section p.author {
    position: absolute;
    bottom: 50px;
    right: 155px;
    font-family: "din", Helvetica, Arial, sans-serif;
    font-weight: bold;
    font-size: 20px;
    color: #d2f0fc;
    text-transform: uppercase;
}

/* <= Large Devices, Wide Screens */
@media only screen and (max-width: 1200px) {
    .new-about img.full-slices {
        position: absolute;
        right: -60px;
        top: -55px;
        width: 730px;
        z-index: 0;
    }

    .body-class-page #twocolumns.new-about #content .wheel-centre p.wheel-number,
    .body-class-page #twocolumns.new-about #content .wheel-items p.wheel-number {
        font-size: 24px;
    }

    .body-class-page #twocolumns.new-about #content .wheel-centre p,
    .body-class-page #twocolumns.new-about #content .wheel-items p {
        font-size: 18px;
    }

    .new-about .wheel-item-1 {
        right: 460px;
        top: 220px;
        width: 125px;
    }

    .new-about .wheel-item-2 {
        right: 400px;
        top: 80px;
        width: 120px;
    }

    .new-about .wheel-item-3 {
        right: 250px;
        top: 30px;
        width: 140px;
    }

    .new-about .wheel-item-4 {
        right: 120px;
        top: 70px;
        width: 120px;
    }

    .new-about .wheel-item-5 {
        right: 40px;
        top: 180px;
        width: 120px;
    }

    .new-about .wheel-item-6 {
        right: 20px;
        top: 300px;
        width: 120px;
    }

    .new-about .wheel-item-7 {
        right: 110px;
        top: 410px;
        width: 120px;
    }

    .new-about .wheel-item-8 {
        right: 270px;
        top: 460px;
        width: 120px;
    }

    .new-about .wheel-item-9 {
        right: 430px;
        top: 380px;
        width: 120px;
    }

    .body-class-page #twocolumns.new-about #content .wheel-centre {
        top: 210px;
        right: 193px;
    }

    .body-class-page-template-template-new-about .quote-section blockquote {
        padding-top: 65px;
        padding-bottom: 105px;
    }
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
    #twocolumns.lower-section {
        min-height: 0;
    }
}

/* <= Small Devices, Tablets */
@media only screen and (max-width: 768px) {

    body.body-class-with-comp {
        top: 60px;
    }

    .banner-wrap {
        height: 75px;
    }

    .new-about img.full-slices {
        display: none;
    }

    .body-class-page-template-template-new-about .quote-section {
        background: #0070CD none;
        height: auto;
    }

    .body-class-page #twocolumns.new-about #content .wheel-items {
        position: relative;
        height: 675px;
    }

    .body-class-page #twocolumns.new-about #content .wheel-items a,
    .body-class-page #twocolumns.new-about #content .wheel-items a:hover {
        text-decoration: none;
    }

    .body-class-page #twocolumns.new-about #content .wheel-items div {
        margin: 0 !important;
    }

    .body-class-page #twocolumns.new-about #content .wheel-items .wheel-text br {
        content: ' ';
    }

    .body-class-page #twocolumns.new-about #content .wheel-items .wheel-text br:after {
        content: ' ';
    }

    .body-class-page #twocolumns.new-about #content .wheel-centre {
        display: none !important;
    }

    .new-about.with-shadows {
        background: none;
        min-height: 0 !important;
    }

    .new-about h1,
    .new-about h2 {
        text-align: center;
        margin: 10px;
        width: 100%;
    }

    .new-about h1 {
        margin-bottom: 30px;
    }

    .new-about h1:after {
        display: none;
    }

    .new-about h3.rollover-to {
        display: none;
    }

    .cta-bar img {
        display: none;
    }

    .body-class-page-template-template-new-about .quote-section blockquote {
        width: 100%;
        font-size: 1.3em;
    }

    .body-class-page-template-template-new-about .cta-bar .btn .text-holder .text {
        font-size: 0.9em;
    }

    .body-class-page-template-template-new-about .quote-section p.author {
        text-align: center;
        position: relative;
        bottom: 50px;
        right: 0;
    }

    .body-class-page #twocolumns.new-about #content .wheel-items div {
        position: relative;
        right: auto;
        top: auto;
        width: 100%;
        margin: 0;
        padding: 10px;
    }

    .body-class-page #twocolumns.new-about #content .wheel-items div:last-child {
        margin-bottom: 30px;
    }

    .wheel-item-1 {
        background: url("/wp-content/themes/blu/images/mob Step 1 Feature.png") no-repeat;
    }

    .wheel-item-2 {
        background: url("/wp-content/themes/blu/images/mob Step 2 Feature.png") no-repeat;
    }

    .wheel-item-3 {
        background: url("/wp-content/themes/blu/images/mob Step 3 Feature.png") no-repeat;
    }

    .wheel-item-4 {
        background: url("/wp-content/themes/blu/images/mob Step 4 Feature.png") no-repeat;
    }

    .wheel-item-5 {
        background: url("/wp-content/themes/blu/images/mob Step 5 Feature.png") no-repeat;
    }

    .wheel-item-6 {
        background: url("/wp-content/themes/blu/images/mob Step 6 Feature.png") no-repeat;
    }

    .wheel-item-7 {
        background: url("/wp-content/themes/blu/images/mob Step 7 Feature.png") no-repeat;
    }

    .wheel-item-8 {
        background: url("/wp-content/themes/blu/images/mob Step 8 Feature.png") no-repeat;
    }

    .wheel-item-9 {
        background: url("/wp-content/themes/blu/images/mob Step 9 Feature.png") no-repeat;
    }
}

/* <= Small Devices, Tablets */
@media only screen and (max-width: 480px) {
    .banner-wrap {
        height: 105px;
    }

    body.body-class-with-comp {
        top: 110px;
    }
}

/* INNER ABOUT PAGE */

.wheel-nav-wrapper {
    background: #F4F2F2;
    display: block;
    height: 83px;
    margin-top: 21px;
}

.wheel-nav-wrapper .wheel-nav {
    display: block;
    width: 1160px;
    margin: 0 auto;
}

.wheel-nav-wrapper .wheel-nav-inner {
    display: block;
    width: 91%;
    margin: 0 auto;
}

.wheel-nav-wrapper .wheel-nav a {
    width: 11.11%;
    float: left;
    display: block;
    padding: 16px 16px 10px 16px;
    background: #F4F2F2;
}

.wheel-nav-wrapper .wheel-nav a,
.wheel-nav-wrapper .wheel-nav a:hover {
    text-decoration: none;
}

.wheel-nav-wrapper .wheel-nav p.wheel-number {
    font-size: 20px;
    font-weight: 300;
    margin-bottom: 0;
}

.wheel-nav-wrapper .wheel-nav p.wheel-text {
    font-size: 12px;
    font-weight: 300;
    text-transform: uppercase;
    margin-bottom: 0;
}

.wheel-nav-wrapper .wheel-nav a:hover p.wheel-number,
.wheel-nav-wrapper .wheel-nav a:hover p.wheel-text {
    color: white !important;
}

.wheel-nav-wrapper .wheel-nav .wheel-nav-1 p {
    color: #ff1b00;
}

.wheel-nav-wrapper .wheel-nav a.wheel-nav-1 {
    border-bottom: 6px #ff1b00 solid;
}

.wheel-nav-wrapper .wheel-nav a.wheel-nav-1:hover {
    background-color: #ff1b00;
}

.wheel-nav-wrapper .wheel-nav .wheel-nav-2 p {
    color: #ff8a00;
}

.wheel-nav-wrapper .wheel-nav a.wheel-nav-2 {
    border-bottom: 6px #ff8a00 solid;
}

.wheel-nav-wrapper .wheel-nav a.wheel-nav-2:hover {
    background-color: #ff8a00;
}

.wheel-nav-wrapper .wheel-nav .wheel-nav-3 p {
    color: #ffc105;
}

.wheel-nav-wrapper .wheel-nav a.wheel-nav-3 {
    border-bottom: 6px #ffc105 solid;
}

.wheel-nav-wrapper .wheel-nav a.wheel-nav-3:hover {
    background-color: #ffc105;
}

.wheel-nav-wrapper .wheel-nav .wheel-nav-4 p {
    color: #65db10;
}

.wheel-nav-wrapper .wheel-nav a.wheel-nav-4 {
    border-bottom: 6px #65db10 solid;
}

.wheel-nav-wrapper .wheel-nav a.wheel-nav-4:hover {
    background-color: #65db10;
}

.wheel-nav-wrapper .wheel-nav .wheel-nav-5 p {
    color: #14d0ff;
}

.wheel-nav-wrapper .wheel-nav a.wheel-nav-5 {
    border-bottom: 6px #14d0ff solid;
}

.wheel-nav-wrapper .wheel-nav a.wheel-nav-5:hover {
    background-color: #14d0ff;
}

.wheel-nav-wrapper .wheel-nav .wheel-nav-6 p {
    color: #0070D7;
}

.wheel-nav-wrapper .wheel-nav a.wheel-nav-6 {
    border-bottom: 6px #0070D7 solid;
}

.wheel-nav-wrapper .wheel-nav a.wheel-nav-6:hover {
    background-color: #0070D7;
}

.wheel-nav-wrapper .wheel-nav .wheel-nav-7 p {
    color: #024489;
}

.wheel-nav-wrapper .wheel-nav a.wheel-nav-7 {
    border-bottom: 6px #024489 solid;
}

.wheel-nav-wrapper .wheel-nav a.wheel-nav-7:hover {
    background-color: #024489;
}

.wheel-nav-wrapper .wheel-nav .wheel-nav-8 p {
    color: #6a20ab;
}

.wheel-nav-wrapper .wheel-nav a.wheel-nav-8 {
    border-bottom: 6px #6a20ab solid;
}

.wheel-nav-wrapper .wheel-nav a.wheel-nav-8:hover {
    background-color: #6a20ab;
}

.wheel-nav-wrapper .wheel-nav .wheel-nav-9 p {
    color: #d03de3;
}

.wheel-nav-wrapper .wheel-nav a.wheel-nav-9 {
    border-bottom: 6px #d03de3 solid;
}

.wheel-nav-wrapper .wheel-nav a.wheel-nav-9:hover {
    background-color: #d03de3;
}

#twocolumns.new-about-inner,
.new-about-inner #content {
    background: none;
}

img.half-wheel {
    position: relative;
    margin: 0 auto;
}

.new-about-inner.headings-wrapper {
    position: absolute;
    top: 194px;
    z-index: 1;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
}

.new-about-inner.headings-wrapper .container {
    width: 100%;
}

.new-about-inner h1 {
    display: block;
    color: white;
    font-family: "din", Helvetica, Arial, sans-serif;
    font-size: 50px;
    font-weight: 600;
    width: 580px;
    margin: 130px auto 0 auto;
    text-align: center;
    line-height: 1.15em;
}

.new-about-inner h1:after {
    display: none;
}

.new-about-inner h2 {
    width: 580px;
    color: white;
    font-family: "din", Helvetica, Arial, sans-serif;
    font-size: 24px;
    font-weight: 200;
    margin: 40px auto 0 auto;
    text-align: center;
    line-height: 1.2em;
}

.new-about-inner #content h3 {
    font-size: 30px;
    font-weight: 200;
    color: #23a2dc;
    text-transform: uppercase;
    margin-bottom: 50px;
}

.new-about-inner #content h3 strong {
    font-weight: 600;
}

.new-about-inner #content h4.list-number {
    float: left;
    color: #23a2dc;
    height: 60px;
    width: 60px;
    border: 3px #23a2dc solid;
    font-size: 38px;
    line-height: 38px;
    margin: 0 25px 25px 0;
    padding: 11px;
    text-align: center;
    font-weight: 200;
}

.new-about-inner #content h4.list-title {
    width: 85%;
    line-height: 27px;
    float: left;
    color: #23a2dc;
    font-size: 24px;
    font-weight: 400;
    margin-top: 6px;
}

.body-class-page #twocolumns.new-about-inner .row p {
    color: #23a2dc !important;
    margin-bottom: 50px;
}

.body-class-page #twocolumns.new-about-inner .row p:last-child {
    margin-bottom: 0;
}

.body-class-page #twocolumns.new-about-inner #sidebar {
    box-shadow: none;
    margin-top: 30px;
}

.body-class-page #twocolumns.new-about-inner #sidebar:after {
    left: 104px;
}

.body-class-page #twocolumns.new-about-inner .quick-tip h4.list-number {
    float: left;
    color: #6b3c96;
    height: 60px;
    width: 60px;
    border: 3px #6b3c96 solid;
    font-size: 38px;
    line-height: 38px;
    margin: 40px 40px 25px 90px;
    padding: 10px 0;
    text-align: center;
    font-weight: 200;
}

.body-class-page #twocolumns.new-about-inner .quick-tip h4.list-title {
    font-size: 20px;
    color: #6b3c96;
    width: 45%;
    line-height: 22px;
    float: left;
    font-weight: 200;
    margin-top: 51px;
    text-transform: uppercase;
}

.body-class-page #twocolumns.new-about-inner .quick-tip p {
    color: black !important;
    margin: 0 0 160px 90px !important;
    font-weight: 300;
    font-size: 16px;
    line-height: 1.2em;
}

.body-class-page-template-template-new-about-inner .from-field-section {
    min-height: 491px;
    height: 491px;
    margin-top: 95px;
    z-index: 10;
    background: url("/wp-content/themes/blu/images/lg-blue-angular-rectangle.png") no-repeat center;
    background-size: cover;
}

.body-class-page-template-template-new-about-inner .from-field-section #content {
    padding-top: 0;
}

.body-class-page-template-template-new-about-inner .from-field-section h3 {
    color: white;
    width: 100%;
    margin: 60px 0 60px 0;
    font-size: 30px;
    font-weight: 200;
}

.body-class-page-template-template-new-about-inner .from-field-section h3 {
    font-weight: 400;
}

.body-class-page-template-template-new-about-inner .from-field-section #content p {
    color: white;
    font-size: 16px;
    font-weight: 200;
}

.body-class-page-template-template-new-about-inner #content .featuring {
    border: none;
    margin: 0;
}

.body-class-page-template-template-new-about-inner #content .featuring:after {
    display: none;
}

.body-class-page-template-template-new-about-inner #content .featuring .video-holder img {
    position: relative;
    top: 1px;
}

.body-class-page-template-template-new-about-inner #content .featuring .video-holder img .play-btn img {
    cursor: pointer;
}

.body-class-page-template-template-new-about-inner .new-about-inner.lower-section #content h3 {
    font-size: 30px;
    color: #23a2dc;
    font-weight: 300;
    text-transform: none;
}

.body-class-page-template-template-new-about-inner .new-about-inner.lower-section #content p {
    font-size: 16px;
    color: black !important;
    line-height: 1em !important;
}

.body-class-page-template-template-new-about-inner .new-about-inner.lower-section #content img {
    /*border-bottom: 10px solid #a9cc48;*/
    margin-bottom: 15px;
}

.body-class-page-template-template-new-about-inner .new-about-inner.lower-section #content .btn.btn-info {
    text-align: center;
    padding: 8px 15px 5px 15px;
    margin: 0;
    text-transform: uppercase;
    border: #23a2dc solid 3px;
    border-radius: 6px;
    color: #23a2dc;
    background: white;
    text-decoration: none;
    font-size: 13px;
    line-height: 14px;
}

.body-class-page-template-template-new-about-inner .new-about-inner.lower-section #content .prev-about-inner a,
.body-class-page-template-template-new-about-inner .new-about-inner.lower-section #content .next-about-inner a {
    font-weight: 400;
    color: #23a2dc;
    font-size: 14px;
    text-decoration: none;
    text-transform: uppercase;
}

.body-class-page-template-template-new-about-inner .new-about-inner.lower-section #content .prev-about-inner strong,
.body-class-page-template-template-new-about-inner .new-about-inner.lower-section #content .next-about-inner strong {
    font-weight: 300;
}

.body-class-page-template-template-new-about-inner .new-about-inner.lower-section #content .prev-about-inner a:hover,
.body-class-page-template-template-new-about-inner .new-about-inner.lower-section #content .next-about-inner a:hover {
    opacity: 0.75;
    text-decoration: none;
}

.body-class-page-template-template-new-about-inner .new-about-inner.lower-section #content .next-about-inner img,
.body-class-page-template-template-new-about-inner .new-about-inner.lower-section #content .prev-about-inner img {
    border: none;
    position: relative;
    top: 0;
}

.body-class-page-template-template-new-about-inner .new-about-inner.lower-section #content .next-about-inner img {
    margin-left: 10px;
}

.body-class-page-template-template-new-about-inner .new-about-inner.lower-section #content .prev-about-inner img {
    margin-right: 10px;
}

@media only screen and (max-width: 1130px) {

    .new-about-inner h1 {
        margin-top: 50px;
        width: 50%;
    }

    .new-about-inner h2 {
        width: 50%;
    }
}

/* <= Small Devices, Tablets */
@media only screen and (max-width: 768px) {
    img.half-wheel {
        display: none;
    }

    .wheel-nav-wrapper {
        display: none;
    }

    .new-about-inner h1,
    .new-about-inner h2 {
        color: #23a2dc;
        width: 80%;
    }

    .new-about-inner h1 {
        font-size: 40px;
        margin: 40px auto 0 auto;
    }

    .new-about-inner h2 {
        width: 80%;
        font-size: 21px;
        margin: 20px auto 0 auto;
    }

    .new-about-inner.headings-wrapper {
        position: relative;
        top: 0;
    }

    #twocolumns.new-about-inner {
        min-height: 0;
        height: auto;
    }

    .new-about-inner #content h4.list-title {
        width: 65%;
    }

    .body-class-page #twocolumns.new-about-inner .quick-tip h4.list-number {
        margin: 0;
    }

    .body-class-page #twocolumns.new-about-inner .quick-tip h4.list-title {
        margin: 10px;
    }

    .body-class-page #twocolumns.new-about-inner .quick-tip p {
        margin: 10px !important;
    }

    .body-class-page #twocolumns.new-about-inner #sidebar:after {
        display: none;
    }

    .body-class-page-template-template-new-about-inner .from-field-section {
        height: auto;
    }
}

/* New Models Page */

#twocolumns.models-nav-wrapper {
    min-height: 0;
}

.map-boy {
    width: 1440px;
    display: block;
    height: 396px;
    margin: 0 auto 0 auto;
    background: url("/wp-content/themes/blu/images/map-boy.png") no-repeat center !important;
}

.models-inner h1 {
    width: 100%;
    margin: 120px auto 0 auto;
    text-align: center;
    font-family: "din", Helvetica, Arial, sans-serif;
    font-size: 50px;
    color: #FFFFFF;
    line-height: 58px;
    font-weight: 200;
    text-transform: none;
    text-decoration: none;
}

.models-inner h1:after {
    display: none;
}

.models-inner h2 {
    width: 66%;
    margin: 40px auto 0 auto;
    text-align: center;
    font-family: "din", Helvetica, Arial, sans-serif;
    font-size: 24px;
    color: #FFFFFF;
    line-height: 37px;
    font-weight: 200;
    text-transform: none;
    text-decoration: none;
}

.models-inner .nav {
    display: inline-flex;
    width: 100%;
    margin: 0 0 25px 0;
    height: 69px;
}

.models-inner.models-nav-wrapper {
    height: 69px;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.04);
    margin-bottom: 25px;
}

.models-inner .models-nav-inner-wrapper {
    margin: auto;
    width: 1140px;
    height: 69px;
}

.models-inner .nav li {
    top: 16px;
    padding: 0 23px;
    margin: 0 auto;
    float: left;
    text-align: center;
}

.models-inner .nav li:hover:after {
    content: ' ';
    display: block;
    height: 4px;
    width: 120%;
    margin-left: -10%;
}

.models-inner .nav li.stat:hover:after {
    background: #3D76BD;
}

.models-inner .nav li.lab:hover:after {
    background: #695096;
}

.models-inner .nav li.ind:hover:after {
    background: #B34B7A;
}

.models-inner .nav li.flip:hover:after {
    background: #E62C2A;
}

.models-inner .nav li.flex:hover:after {
    background: #F5700A;
}

.models-inner .nav li.ala:hover:after {
    background: #F1CA06;
}

.models-inner .nav li.enrich:hover:after {
    background: #91BF33;
}

.models-inner .col-sm-6 a.video:hover {
    text-decoration: none;
    opacity: .75;
}

.models-inner .nav li a {
    padding: 0 0 25px 0;
    font-weight: 300;
    margin: 0;
    font-size: 15px;
    text-transform: uppercase;
    text-decoration: none;
    color: #656D71;
}

.models-inner .nav li:hover a {
    text-decoration: none;
}

.models-inner .nav li.stat:hover a {
    color: #3D76BD;
}

.models-inner .nav li.lab:hover a {
    color: #695096;
}

.models-inner .nav li.ind:hover a {
    color: #B34B7A;
}

.models-inner .nav li.flip:hover a {
    color: #E62C2A;
}

.models-inner .nav li.flex:hover a {
    color: #F5700A;
}

.models-inner .nav li.ala:hover a {
    color: #F1CA06;
}

.models-inner .nav li.enrich:hover a {
    color: #91BF33;
}

.models-inner .col-sm-6 h2 {
    width: 100%;
    text-align: left;
    margin: 0;
    padding: 0;
    font-family: "din", Helvetica, Arial, sans-serif;
    font-weight: 200;
    font-size: 50px;
    color: #22313A;
    line-height: 58px;
}

.models-inner .col-sm-6 h2.right {
    text-align: left;
}

.models-inner .col-sm-6 p {
    margin: 0;
    padding: 0;
    font-family: "din", Helvetica, Arial, sans-serif;
    font-weight: 200;
    font-size: 16px;
    color: #656D71 !important;
    line-height: 30px;
}

.models-inner .col-sm-6 a.video {
    margin-top: 10px;
    cursor: pointer;
    text-transform: uppercase;
    line-height: 60px;
    display: block;
    text-decoration: none;
    text-align: center;
    width: 196px;
    height: 58px;
    border-radius: 100px;
    font-family: "din", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: #FFFFFF;
    letter-spacing: 1px;
}

#twocolumns.models-inner.stat-back {
    background: #ffffff !important;
}

#twocolumns.models-inner.lab-back {
    background: #E1DCEA !important;
}

#twocolumns.models-inner.flip-back {
    background: #FADBDB !important;
}

#twocolumns.models-inner.ala-back {
    background: #FCF4CD !important;
}

.models-inner .col-sm-6 a.video {
    float: left;
    margin-right: 10px;
}

.models-inner .col-sm-6 a.model-in-action {
    width: 195px;
    padding-left: 10px;
    padding-right: 10px;
}

.models-inner .col-sm-6 a.profile {
    width: 120px;
}

.models-inner .col-sm-6 a.video.stat {
    background: #3D76BD;
}

.models-inner .col-sm-6 a.video.lab {
    background: #695096;
}

.models-inner .col-sm-6 a.video.ind {
    background: #B34B7A;
}

.models-inner .col-sm-6 a.video.flip {
    background: #E62C2A;
}

.models-inner .col-sm-6 a.video.flex {
    background: #F5700A;
}

.models-inner .col-sm-6 a.video.ala {
    background: #F1CA06;
}

.models-inner .col-sm-6 a.video.enrich {
    background: #91BF33;
}

.models-inner .col-sm-6 a.video:hover {
    text-decoration: none;
    opacity: .75;
}

.models-inner .col-sm-6 a.video img {
    width: 16px;
    position: relative;
    left: -12px;
    top: 19px;
}

.models-inner .col-sm-6 .icon {
    position: absolute;
    top: 0;
    left: -100px;
    width: 160px;
    opacity: 0.15;
    z-index: 0;
}

.models-inner .col-sm-6 .icon.right {
    top: 70px;
    left: auto;
    right: -60px;
}

#twocolumns.models-inner {
    background: none !important;
}

.models-inner .table {
    position: relative;
    display: table;
    height: 500px;
}

.models-inner .table-cell {
    position: relative;
    display: table-cell;
    height: 100%;
    vertical-align: middle;
}

/* <= Large Devices, Wide Screens */
@media only screen and (max-width: 1440px) {
    .map-boy {
        width: 100%;
        background-size: contain !important;
        background: url("/wp-content/themes/blu/images/map-boy.png") no-repeat center !important;
    }

    .map-boy h1,
    .map-boy h2 {
        width: 100%;
    }
}

/* <= Large Devices, Wide Screens */
@media only screen and (max-width: 1200px) {
    .models-inner .nav li {
        padding: 0 8px;
    }

    .map-boy {
        width: 100%;
        background-size: contain !important;
        background: url("/wp-content/themes/blu/images/map-boy.png") no-repeat center !important;
    }

    .map-boy h1,
    .map-boy h2 {
        width: 100%;
    }
}

/* <= Special */
@media only screen and (max-width: 940px) {
    .models-inner.models-nav-wrapper {
        display: none;
    }
}

/* <= Large Devices, Wide Screens */
@media only screen and (max-width: 768px) {

    .map-boy {
        background: url("/wp-content/themes/blu/images/map-boy.png") no-repeat -640px center !important
    }

    .models-inner #content.col-lg-6,
    .models-inner #content.col-lg-5 {
        padding-left: 30px;
        padding-right: 30px;
    }

    .models-inner .nav {
        display: none;
    }

    .models-inner .col-sm-6 .icon {
        width: 75px;
        left: -10px;
        top: 40px;
    }

    .models-inner .col-sm-6 .icon.right {
        width: 75px;
        right: -20px;
        top: 10px;
    }

    .models-inner .table {
        height: 350px;
    }

    .models-inner .col-sm-6 h2 {
        font-size: 43px;
    }

    .map-boy h1 {
        margin-top: 130px;
        font-size: 28px;
    }

    .map-boy h2 {
        font-size: 18px;
        line-height: 26px;
        width: 75%;
    }
}

/* New Learning Page */

.learning-page #twocolumns {
    background: none;
}

.learning-page .classroom1 {
    width: 1440px;
    display: block;
    height: 533px;
    margin: 25px auto 0 auto;
    background: url("/wp-content/themes/blu/images/classroom-bg.png") no-repeat center !important;
}

.body-class-page-template-template-new-about .classroom2,
.learning-page .classroom2 {
    width: 1440px;
    display: block;
    height: 396px;
    margin: 0 auto 0 auto;
    background: url("/wp-content/themes/blu/images/classroom-bg-2.png") no-repeat center !important;
}

.body-class-page-template-template-new-about .classroom2 {
    position: relative;
    top: -41px;
}

.body-class-page-template-template-new-about footer {
    margin-top: -41px;
}

.learning-page h1 {
    width: 100%;
    margin: 80px auto 0 auto;
    text-align: center;
    font-family: "din", Helvetica, Arial, sans-serif;
    font-size: 50px;
    color: #FFFFFF;
    line-height: 58px;
    font-weight: 200;
    text-transform: none;
    text-decoration: none;
}

.learning-page h1:after {
    display: none;
}

.learning-page h2 {
    width: 66%;
    margin: 40px auto 50px auto;
    text-align: center;
    font-family: "din", Helvetica, Arial, sans-serif;
    font-size: 24px;
    color: #FFFFFF;
    line-height: 37px;
    font-weight: 200;
    text-transform: none;
    text-decoration: none;
}

.learning-page .row .sections {
    margin: 0 auto;
    width: 1140px;
}

.learning-page .row .sections .plus-sign {
    position: absolute;
    z-index: 10;
}

.learning-page .row .sections .plus-sign-left {
    left: -44px;
    top: 69px;
}

.learning-page .row .sections .plus-sign-right {
    right: -44px;
    top: 69px;
}

.learning-page .section {
    display: table;
    height: 201px;
    background: white;
    border-radius: 10px;
}

.learning-page .section .inner {
    display: table-cell;
    vertical-align: middle;
}

.learning-page .section h3 {
    text-align: center;
    font-family: "din", Helvetica, Arial, sans-serif;
    font-size: 22px;
    color: #22313A;
    font-weight: 400;
}

.learning-page .section p {
    line-height: 23px;
    padding: 0 20px;
    text-align: center;
    font-family: "din", Helvetica, Arial, sans-serif;
    font-size: 15px;
    color: #656D71;
    font-weight: 400;
}

.learning-page .classroom1 .section-1 {
    position: relative;
    left: -30px;
}

.learning-page .classroom1 .section-3 {
    position: relative;
    left: 30px;
}

.the-models {
    margin-top: 70px;
    display: table;
    height: 462px;
    background: #695096;
}

.the-models .inner {
    display: table-cell;
    vertical-align: middle;
}

.the-models h3 {
    color: #ECECEC;
    font-size: 50px;
    padding: 0 65px;
    font-weight: 200;
}

.body-class-page #twocolumns #content .the-models p {
    color: #ECECEC !important;
    font-size: 24px;
    padding: 0 65px;
    font-weight: 200;
}

.the-models a,
.the-models a:hover {
    font-weight: 200;
    color: #ECECEC;
    font-size: 15px;
    text-decoration: none;
    padding: 0 65px;
    text-transform: uppercase;
}

.the-models a:hover {
    opacity: 0.75;
}

.the-models a img {
    position: relative;
    top: -4px;
    width: 12px;
    height: auto;
}

.the-model-icons {
    display: table;
    margin: 70px auto 70px auto;
}

.the-model-icons .model-icon {
    margin-bottom: 2px;
}

.the-model-icons .model-icon:last-child {
    margin-bottom: 0;
}

.the-model-icons .inner {
    display: table-cell;
    vertical-align: middle;
}

.the-model-icons a {
    text-decoration: none;
}

.the-model-icons a:hover {
    opacity: 0.75;
    text-decoration: none;
}

.the-model-icons h4 {
    width: 280px;
    font-weight: 200;
    font-size: 22px;
    color: #22313A;
    position: relative;
    top: 20px;
    left: 20px;
}

.the-model-icons img {
    width: 64px;
    float: left;
}

.why-blended {
    background: #EBF1F8;
}

.why-blended h2 {
    width: 100%;
    text-align: center;
    color: #22313A;
    font-size: 50px;
    margin-top: 56px;
    font-weight: 200;
    margin-bottom: 40px;
}

.why-blended h3 {
    margin: 0 auto 50px auto;
    width: 54%;
    text-align: center;
    color: #656D71;
    font-size: 24px;
    font-weight: 200;
}

.why-blended a,
.why-blended a:hover {
    font-weight: 200;
    color: #656D71;
    font-size: 15px;
    text-decoration: none;
    padding: 0 65px;
    text-transform: uppercase;
}

.why-blended a:hover {
    opacity: 0.75;
}

.why-blended .section {
    display: table;
    height: 383px;
    background: white;
    border-radius: 10px;
    margin-bottom: 80px;
}

.why-blended .section .inner {
    text-align: center;
    display: table-cell;
    vertical-align: middle;
}

.why-blended .section h3 {
    width: 85%;
    margin-top: 10px;
    margin-bottom: 25px;
}

.why-blended .section .inner img {
    margin-bottom: 35px;
}

.why-blended .section .inner p {
    margin-bottom: 29px;
}

.why-blended .section .inner a {
    font-weight: 600;
    color: #22313A;
}

.why-blended .section .inner a img {
    position: relative;
    top: -3px;
    width: 12px;
    height: auto;
    margin-bottom: 0;
}

.why-blended .section-1 {
  position: relative;
    left: -30px;
}

.why-blended .section-3 {
  position: relative;
    left: 30px;
}

.body-class-page-template-template-new-about .resources,
.learning-page .resources {
    margin: 0 auto;
    text-align: center;
}

.body-class-page-template-template-new-about .resources h2,
.learning-page .resources h2 {
    text-align: center;
    padding-top: 125px;
    font-size: 50px;
    margin: 0 auto;
    color: white;
    font-weight: 200;
}

.body-class-page-template-template-new-about .resources h3,
.learning-page .resources h3 {
    padding-top: 27px;
    text-align: center;
    width: 33%;
    font-size: 24px;
    margin: 0 auto;
    color: white;
    font-weight: 200;
}

.body-class-page-template-template-new-about .resources a,
.learning-page .resources a {
    padding-top: 38px;
    display: inline-block;
    text-align: center;
    color: white;
    font-weight: 200;
    font-size: 15px;
    text-decoration: none;
    text-transform: uppercase;
}
.body-class-page-template-template-new-about .resources a img,
.learning-page .resources a img {
    position: relative;
    top: -3px;
    width: 12px;
    height: auto;
    margin-bottom: 0;
}

/* <= Large Devices, Wide Screens */
@media only screen and (max-width: 1440px) {

    .learning-page .classroom1,
    .learning-page .classroom2,
    .body-class-page-template-template-new-about .classroom2 {
        width: 100%;
    }

    .learning-page .row .sections {
        width: 90%;
    }

    .classroom1 {
        background-size: contain !important;
        background: url("/wp-content/themes/blu/images/classroom-bg.png") no-repeat center !important;
    }

    .classroom1 h1,
    .classroom1 h2 {
        width: 100%;
    }
}

@media only screen and (max-width: 1100px) {

    .learning-page .row .sections .plus-sign-left {
        left: -33px;
    }

    .learning-page .row .sections .plus-sign-right {
        right: -33px;
    }

    .body-class-page-template-template-new-about .resources h2,
    .learning-page .resources h2 {
        padding-top: 85px;
    }
}

/* <= Large Devices, Wide Screens */
@media only screen and (max-width: 768px) {

    .learning-page .classroom1 .section-1,
    .learning-page .classroom1 .section-3,
    .why-blended .section-1,
    .why-blended .section-3 {
        position: relative;
        left: auto;
    }

    .the-models {
        margin-top: 0;
    }

    .why-blended-sections {
        margin: 0 10px;
    }

    .why-blended h2 {
        line-height: 52px;
    }

    .body-class-page-template-template-new-about .resources h3,
    .why-blended h3,
    .learning-page .resources h3{
        width: 90%;
    }

    .why-blended #content.section {
        height: 340px !important;
    }

    .learning-page .section {
        height: 151px;
        border-radius: 0;
    }

    .learning-page .section,
    .learning-page .why-blended .section {
        margin-bottom: 20px;
    }

    .learning-page .classroom1 {
        height: 840px;
        background: url("/wp-content/themes/blu/images/classroom-bg.png") no-repeat center -100px !important;
    }

    .learning-page .plus-sign-wrapper {
        position: absolute;
        left: 50%;
    }

    .learning-page .row .sections .plus-sign-left {
        position: relative;
        left: -50%;
        top: -169px;
    }

    .learning-page .row .sections .plus-sign-right {
        position: relative;
        left: -50%;
        top: -4px;
        right: auto;
    }

    .classroom1 h1 {
        margin-top: 130px;
        font-size: 28px;
    }

    .classroom1 h2 {
        font-size: 18px;
        line-height: 26px;
        width: 75%;
    }

    .learning-page .why-blended .section,
    .learning-page .section {
        width: 100% !important;
    }
}


.paper-section #content{
	max-width: 558px;
	margin: 0 auto;
	padding: 20px 10px;
	.img-responsive {
		box-shadow: 6px 6px 20px rgba(0, 0, 0, 0.15);
		padding-bottom: 20px;
	}
}
