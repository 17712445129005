#footer {
	.container-responsive {
		display: flex !important;
		flex-wrap: wrap;
		align-items: center;
		padding: 0 10px;
		justify-content: space-between;
	}
	.footer-nav {
		flex: 0 0 100%;
	}
	.footer-top {
		background: #0070cd;
		padding-top: 15px;
		.container-responsive {
			display: flex;
		}
		.logo {
			width: 192px;
			height: 28px;
			margin-bottom: 32px;
		}

		.logo-ci {
			width: 122px;
			height: 72px;
			margin: 0 auto;

			img {
				display: block;
				width: 72px;
				height: 72px;
			}
		}
	}

	@media only screen and (max-width: 767px) {
		.footer-top {
			padding-top: 10px;
		}
	}

	@media only screen and (max-width: 767px) {
		.footer-top .logo {
			margin-bottom: 25px;

			img {
				width: 100%;
				height: auto;
			}
		}
	}

	@media only screen and (max-width: 767px) {
		.footer-top .logo-ci {
			margin-bottom: 20px;
			margin-right: 0;
			text-align: right;
		}
	}

	@media only screen and (max-width: 767px) {
	}
	.footer-bottom {
		background: #0a3e6a;
		overflow: hidden;

		.footer-copyright {
			flex: 1 1 auto;
		}
		.footer-social {
			flex: 1 1 auto;
		}
		.copyright {
			color: #fff;
			font-size: 10px;
			line-height: 14px;
			//padding: 15px 0 11px;
			a {
				font-weight: bold;
				color: #fff;
				text-decoration: none;

				&:hover {
					text-decoration: underline;
				}
			}
		}

		.more-info {
			font-size: 10px;
			line-height: 12px;
			font-weight: normal;
			color: white;
			flex: 0 0 100%;
			span {
				display: block;
				padding: 5px 0;
			}

			a {
				font-weight: bold;
				text-decoration: none;
				color: #0070cd;

				&:hover {
					text-decoration: underline;
				}
			}
		}

		.social-networks {
			font-size: 0;
			line-height: 0;
			letter-spacing: -4px;
			position: relative;
			white-space: nowrap;

			&:before {
				content: "";
				position: absolute;
				top: 0;
				left: 100%;
				background: #016bc3;
				width: 999px;
				height: 100%;
				z-index: 1;
			}

			li {
				list-style: none;
				display: inline-block;
				vertical-align: middle;
				font-size: 14px;
				line-height: 1px;
				letter-spacing: 0;
				position: relative;
				z-index: 5;

				&.facebook a {
					background: #08487e;
				}

				&.twitter a {
					background: #065292;
				}

				&.youtube a {
					background: #045ca5;
				}

				&.linkedin a {
					background: #0266b9;
				}

				a {
					color: #fff;
					text-decoration: none;
					width: 39px;
					height: 40px;
					display: block;
					text-align: center;

					&:after {
						content: "";
						display: inline-block;
						vertical-align: middle;
						height: 100%;
					}

					&:hover {
						background: #036;
					}

					span {
						display: inline-block;
						vertical-align: middle;
					}
				}

				.stButton {
					display: none !important;
				}
			}
		}
	}

	@media only screen and (max-width: 767px) {
		.footer-bottom .social-networks {
			text-align: left !important;
		}
	}

	@media only screen and (max-width: 767px) {
		.footer-bottom .social-networks:before {
			display: none;
		}
	}
}
