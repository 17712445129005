
.mc-container {
    background: #3FA4F0;
    height: 250px;
}

.mc-container .container {
    height: 250px;
    background: url("/wp-content/themes/blu/images/newsletter-background.png") no-repeat center center;
}

.mc-container h3 {
    color: white;
    text-transform: none;
    font-size: 24px;
    font-weight: 300;
    margin-bottom: 15px;
}

.mc-container h4 {
    margin-top: 50px;
    color: white;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 400;
}

.mc-container #mc_embed_signup {
    margin-top: 50px;
    color: white;
}

.mc-container #mc_embed_signup input[type=email],
.mc-container #mc_embed_signup input[type=text] {
    margin-bottom: 13px;
    width: 220px;
    height: 40px;
    background: #3FA4F0;
    color: white;
    border: 2px solid white;
    border-radius: 0;
}

.mc-container #mc_embed_signup input[name=LNAME] {
    left: -40px;
    position: relative;
}

.mc-container #mc_embed_signup input[type=email] {
    width: 460px;
}

.mc-container #mc_embed_signup input[type=submit] {
    margin-left: 10px;
    color: white;
    border: 2px solid white;
    text-transform: uppercase;
    border-radius: 4px;
}

.mc-container #mc_embed_signup ::-webkit-input-placeholder {
    color: white;
}

.mc-container #mc_embed_signup :-moz-placeholder { /* Firefox 18- */
    color: white;
}

.mc-container #mc_embed_signup ::-moz-placeholder {  /* Firefox 19+ */
    color: white;
}

.mc-container #mc_embed_signup :-ms-input-placeholder {
    color: white;
}

@media only screen and (max-width: 768px) {
    .mc-container h3 {
        margin-bottom: 0;
    }

    .mc-container #mc_embed_signup {
        margin-top: 20px;
    }

    .mc-container,
    .mc-container .container {
        height: auto;
    }

    .mc-container #mc_embed_signup input[name=LNAME] {
        left: 0;
    }

    .mc-container #mc_embed_signup input[type=text],
    .mc-container #mc_embed_signup input[type=email],
    .mc-container #mc_embed_signup input[name=LNAME] {
        width: 100%;
    }

    .mc-container #mc_embed_signup input[type=submit] {
        line-height: 23px;
        height: 30px;
    }
}