/* =WordPress Core
-------------------------------------------------------------- */

.sticky,
.bypostauthor{
	display:block;
}
.btn-default {
	text-decoration:none;
}
.post-edit-link,.navigation{
	margin-bottom:15px;
	display:block;
}
.meta ul,.meta ul li{
	list-style-type:none;
	padding-left:0px;
}
.no-sidebar #content{
	border-right:none;
}
.footer-bottom .social-networks li a{cursor:pointer}
.not_found{min-height:250px;}


.container-responsive {
	max-width: 960px;
	margin: 0 auto;
}
